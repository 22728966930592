import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class LocationPage extends Component {
  state = {};
  render() {
    var settings = {
      className: "variable-width",
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { length: count } = this.props.locations;
    const { locations } = this.props;

    if (count === 0) {
      return <p />;
    }

    return (
      <div>
        <div className="section-title">
          <h1 className="section-head">{locations.title}</h1>
        </div>
        <section className="recom-slider slider">
          <Slider {...settings} className="">
            {locations.data.map((location) => (
              <div
                className="recom-box-outer variable-width"
                key={location.service_location_id}
              >
                <Link
                  to={`/category/${location.service_location_name}/${location.api_page_type_id}/${locations.api_page_type}`}
                  className="recom-box"
                >
                  <div className="recom-img">
                    <img
                      src={location.service_location_picture}
                      alt={location.service_location_name}
                    />
                  </div>
                  <div className="recom-overlay" />
                  <div className="recom-content">
                    <h5>{location.service_location_name}</h5>
                    {/* <p>{t("100_night_average")}</p> */}
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </section>
      </div>
    );
  }
}

export default LocationPage;
