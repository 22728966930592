import React, { Component } from "react";
import CalForm from "../Helper/calForm";
import FaqSection from "../Helper/faqSection";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

class BecomeAHost extends Component {
  state = {};
  componentDidMount() {
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
  }
  render() {
    return (
      <div>
        <div
          className="host-banner-sec"
          style={{
            backgroundImage: `url('../../../assets/img/provider.jpg')`
          }}
        >
          <div className="host-banner-sec-overlay">
            <div className="site-content">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 host-banner-aligncenter">
                  <div className="">
                    <h2 className="host-banner-subhead">{t("host_on_site",{siteName: configuration.get(
                    "configData.site_name")})}</h2>
                    <h1 className="host-banner-head">
                      {t("earn_money_as_an_site_host",{siteName: configuration.get(
                    "configData.site_name")})}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="host-details-sec banner-mobileview">
          <div className="host-details-head">
            <h2>{t("find_out_what_top_hosts_earn_in_your_area")}</h2>
          </div>
          <form className="host">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="bangaluru"
              />
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>4 guests</option>
                <option>1 guests</option>
                <option>2 guests</option>
                <option>3 guests</option>
                <option>4 guests</option>
              </select>
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>Entire palce</option>
                <option>Private room</option>
                <option>shared room</option>
              </select>
            </div>
            <div>
              <h1 className="amount">₹18,296</h1>
              <h4 className="amount-subhead">per month</h4>
            </div>
            <div className="mt-4">
              <button className="pink-btn">get started</button>
            </div>
          </form>
        </div>

        <div className="main">
          <div className="provider-features">
            <div className="site-content">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <h2 className="features-head">{t("why_host_on_site",{siteName: configuration.get(
                    "configData.site_name")})}</h2>
                  <h4 className="features-para">
                    {t("why_host_on_site_text",{siteName: configuration.get(
                    "configData.site_name")})}
                  </h4>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <h2 className="features-head">{t("you_are_in_control")}</h2>
                  <h4 className="features-para">
                    {t("you_are_in_control_text",{siteName: configuration.get(
                    "configData.site_name")})}
                  </h4>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <h2 className="features-head">{t("We_are_there_at_every_step")}</h2>
                  <h4 className="features-para">
                    {t("We_are_there_at_every_step_text",{siteName: configuration.get(
                    "configData.site_name")})}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-content">
          <div className="host-top-bottom-spacing">
            <div className="host-section-head">
              <h1>{t("how_to_be_an_site_host",{siteName: configuration.get(
                    "configData.site_name")})}</h1>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">
                <img src="../assets/img/listing1.jpg" className="listing-img" alt="Listing1" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">1</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("create_your_listing")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para1",{siteName: configuration.get(
                    "configData.site_name")})}
                      </p>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
                <img src="../assets/img/listing1.jpg" className="listing-img" alt="Listing 1" />
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src="../assets/img/listing2.jpg" className="listing-img" alt="Listing 2"/>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">2</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("create_your_listing")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para1",{siteName: configuration.get(
                    "configData.site_name")})}
                      </p>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">
                <img src="../assets/img/listing1.jpg" className="listing-img" alt="Listing 3" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="media">
                  <h1 className="count">3</h1>
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("create_your_listing")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para1",{siteName: configuration.get(
                    "configData.site_name")})}
                      </p>
                    </div>
                    <div className="listings-para">
                      <p>
                        {t("create_your_listing_para2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
                <img src="../assets/img/listing1.jpg" className="listing-img" alt="Listing 4" />
              </div>
            </div>
          </div>
        </div>
        <div className="site-content">
          <div className="host-top-bottom-spacing">
            <div className="host-section-head">
              <h1>{t("safety_on_site",{siteName: configuration.get(
                    "configData.site_name")})}</h1>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon1.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>₹{t("60000000_host_gurantee")}</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon2.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>{t("host_protection_insurance")}</h2>
                    <p>
                      {t("host_protection_insurance_text",{siteName: configuration.get(
                    "configData.site_name")})}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="media safety">
                  <img
                    src="../assets/img/safety-icon3.png"
                    alt="safety-icon"
                    className="mr-3 rounded-circle review-img"
                  />
                  <div className="media-body">
                    <h2>{t("site_is_built_on_trust",{siteName: configuration.get(
                    "configData.site_name")})}</h2>
                    <p>
                      {t("site_is_built_on_trust_text",{siteName: configuration.get(
                    "configData.site_name")})}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FaqSection />

        <div
          className="host-footer-img"
          style={{
            backgroundImage: `url('../../../assets/img/footer.jpg')`
          }}
        >
          <div className="site-content">
            <div className="row">
              <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5">
                <div className="host-footer-content">
                  <div>
                    <div className="host-footer-content-head">
                      <h1>{t("start_creating_your_listing")}</h1>
                    </div>
                    <a href="/host/login" className="pink-btn">
                      {t("get_started")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(BecomeAHost));
