import React, { Component } from "react";

import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

class HomeFrontStaticContent extends Component {
    state = {};
    render() {
        return (
            <div>
                <div className="section-title">
                    <h1 className="section-head">{t("introducing_site_plus",{siteName: configuration.get(
                    "configData.site_name")})}</h1>
                </div>
                <div className="add-img-sec">
                    <img
                        srcSet="../../../assets/img/add1.jpg,
                                                ../../../assets/img/add1.jpg 1.5x,
                                                ../../../assets/img/add1.jpg 2x"
                        src="../../../assets/img/add1.jpg"
                        alt="introducing"
                        className="add-img"
                    />
                    <div className="add-text">
                        <h3 className="captalize">
                            {t("introducing_site_plus_text")}
                        </h3>
                        <div>
                            <Link to="#" className="white-btn banner-white-btn">
                                {t("explore_site_plus_homes",{siteName: configuration.get(
                    "configData.site_name")})}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeFrontStaticContent;
