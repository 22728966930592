import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class HostForgotPassword extends Component {
  state = {};
  render() {
    return (
      <div className="page-content">
        <div className="prov-login">
          <div className="log-head">
            <h4>{t("reset_password")}</h4>
            <p>
              {t("reset_password_text")}
            </p>
          </div>
          <form className="top1 prov-login-form">
            <div className="form-group input-group">
              <input
                type="text"
                className="form-control"
                placeholder={t("email_address")}
              />
            </div>
            <button className="pink-btn bottom1">{t("send_reset_link")}</button>
          </form>
        </div>
      </div>
    );
  }
}

export default translate(HostForgotPassword);
